import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import {
  Redirect
} from 'react-router-dom'
import axios from "axios";

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));

class App extends Component {

  constructor(props) {
    super(props);

    axios.defaults.baseURL = process.env.REACT_APP_SEKAWAN_URL;
    axios.interceptors.request.use(config => {
      let isAuthenticated = !!localStorage.getItem("isAuthenticated");

      if (isAuthenticated) {
        const token = localStorage.getItem("token");
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    }, error => {
      return Promise.reject(error);
    });
  }

  componentDidMount() {
    const roleId = localStorage.getItem("roleId");
    if(roleId) {
      axios.get(`v1/internal/access/detail/${roleId}/menu`)
      .then(res => {
        if (res.status === 200) {
          localStorage.setItem('accessMenu', JSON.stringify(res.data.data))
        } else {
          console.log(res)
        }
      })
    }
  }

  render() {
    return (
      <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <PrivateRoute path='/' render={props => <DefaultLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
}

function PrivateRoute ({component: Component, ...rest}) {
  let isAuthenticated = !!localStorage.getItem("isAuthenticated");
  const roleIdFromLs = !!localStorage.getItem("roleId")

  return (
      <Route
    {...rest}
    render={(props) => isAuthenticated === true && roleIdFromLs === true
      ? <DefaultLayout {...props} />
  : <Redirect to={{pathname: '/login', state: {from: '/' }}} />}
    />
  )
}

export default App;
